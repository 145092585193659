import React, { Component } from 'react';
import {Grid, Stack} from "@mui/material";
import './Layout.css';
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Button from '@mui/material/Button';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
const Header = () => {
	return (
		<Grid container>
		<Grid xs={2}>
<Link component={"a"}  to="/" underline="none" sx={{mr:2, display:{md:'block'}}} >
		<Box component="img" src="/logo.png" sx={{height:'30px'}} />
</Link>
		</Grid>
		<Grid  container
		direction="row"
		justifyContent="center"
		alignItems="center" xs={8}>
		<Box  sx={{display: {xs: 'none', md: 'block'},  border: 0}}>
		<Stack direction="row" spacing={2} className={'nav'}>
		<Link component={"a"}  to="/" underline="none" sx={{mr:2, display:{md:'block'}}} >
		<Typography sx={{fontSize:'15px', fontWeight:700}}><DashboardIcon sx={{verticalAlign:"middle"}}/> Home</Typography>
		</Link>



		<Link tag={Button}  to="/projects">
		<Typography sx={{fontSize:'15px', fontWeight:700}}><CastConnectedIcon sx={{verticalAlign:"middle"}}/> Projects</Typography>
		</Link>


		<Link tag={Button}  to="/export">
		<Typography sx={{fontSize:'15px', fontWeight:700}}><CastConnectedIcon sx={{verticalAlign:"middle"}}/> Export</Typography>
		</Link>
		</Stack>

		</Box>
		</Grid>
		</Grid>
	)
};

export class Layout extends Component {
	static displayName = Layout.name;

	render() {
		return (
			<React.Fragment>
			<Container sx={{marginTop:2}}>

			<Header sx={{my:5}} />
			<Box sx={{my:3}}>{this.props.children}</Box>
			</Container>
			</React.Fragment>
		);
	}
}
