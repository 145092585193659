import React from 'react';
import axios from 'axios';
//const API_URL = 'https://api.annoset.com/api/';
import {getConfig} from './config';

export class UserManager {
    constructor(){
        this.config = getConfig();
        this.api_url = this.config.api_domain + "/api/";
        this.access_token = "";
    }

    setAccessToken(token){
        this.access_token = token;
    }

    getAccessToken(){
        return this.access_token;
    }
  async secureFetch(url, options = {}) {
    var ot = this;
  //const user = JSON.parse(localStorage.getItem('user')) || {};
  const headers = {
    'Authorization': `Bearer ${this.access_token}`,
    ...options.headers,
  };

  return fetch(url, { credentials: "include", ...options, headers }).then(async response => {
    if (response.status === 401) {
      // Attempt to refresh token
      const refreshResponse = await ot.refreshToken();
      if (refreshResponse.access) {
        headers['Authorization'] = `Bearer ${ot.getAccessToken()}`;
        // Retry the original request with new token
        return fetch(url, { ...options, headers });
      } else {
        // Handle failure: redirect to login, clear storage, etc.
        console.error("Failed to refresh token.");
        // Potentially redirect to login here
        //localStorage.deleteItem('user');
      }
    }
    return response;
  });
  }
  isAuthenticated() {
    return this.access_token && this.access_token.length > 0;
    /*const user = localStorage.getItem('user');
    console.log(user);
    if (user) {
      const { access } = JSON.parse(user);
      if (!access) {
        console.log("no access token")
        return false;
      }

      // Decode the JWT to check expiration
      const payload = JSON.parse(atob(access.split('.')[1]));
      console.log(payload);
      // JWT exp is in seconds
      const isExpired = Date.now() >= payload.exp * 1000;
      return !isExpired;
    }
    return false;*/
  }
  login(email, password) {
        let t = this;
    return axios
      .post(this.api_url + 'token/', { email, password },{ withCredentials: true })
      .then(response => {
        if (response.data.access) {
            console.log(response.data);
            t.setAccessToken(response.data.access);
          //localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  refreshToken() {
    //const user = JSON.parse(localStorage.getItem('user'));
      let t = this;
    return axios
      .post(this.api_url + 'token/refresh/', {},{ withCredentials: true })
        .catch((e)=>{
return false;
        })
      .then(response => {
          console.log(response);
        if (response && response.data && response.data.access) {
          //user.access = response.data.access;
          //localStorage.setItem('user', JSON.stringify(user));
            t.setAccessToken(response.data.access);
        }

        return response.data ?? false;
      });
  }
getnewAccessTokenIfPossible() {
        console.log("here");
    if (this.isAuthenticated()) {
      console.log("Access token is valid. No need to refresh.");
      return Promise.resolve({ success: true, message: "Token is still valid" });
    } else {
      //const user = localStorage.getItem('user');
      //if (user && JSON.parse(user).refresh) {
        return this.refreshToken()
          .then(response => {
              console.log(response);
            if (response && response.access) {
                this.access_token = response.access;
              console.log("Access token refreshed successfully.");
              // Optionally re-check authentication
              if (this.isAuthenticated()) {
                console.log("User is authenticated with new token.");
                return { success: true, message: "Token refreshed and user is authenticated" };
              } else {
                console.log("Failed to authenticate with new token.");
                return { success: false, message: "Token refreshed but authentication failed" };
              }
            } else {
              console.log("1Failed to refresh token.");
              return { success: false, message: "Failed to refresh token" };
            }
          }).catch((e) => {
              console.log(e);
                return { success: false, message: "Failed to refresh token" };
            });
      //} else {
      //  console.log("No refresh token available.");
      //  return Promise.resolve({ success: false, message: "No refresh token available" });
      //}
    }
  }


}

// Create a single instance of UserManager
export const userManager = new UserManager();

// Create a context with the UserManager instance
export const UserManagerContext = React.createContext(userManager);
